.Billet{
	&-Intro{
		padding:43px 0px;
	}

	&-Txt{
		position:relative;
		z-index:10;
		padding:96px 0px 50px 0px;


		h3{
			color:$color__talents_jaune;
			font-weight:700;
		}

		p{
			@include font-size(1.25);
			color:#fff;
			a{
				color:$color__talents_rose;
				&:hover{
					text-decoration:underline;
				}
			}
		}

		@media screen and (max-width: 767px) {
			padding:60px 0px 20px 0px;
			h3{
				font-size:1.1em;
			}
			p{
				@include font-size(1);
			}
		}
	}
}


.section-bg{
	background:url('../imgs/bg-section-bottom.jpg') center center;
	min-height: 395px
}

.Contact{
	&-Intro{
		padding:60px 0px;
		p{
			margin-bottom: 0px;
		}
	}
}