/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */

	&:focus {
		background-color: $color__background-screen;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		clip-path: none;
		color: $color__text-screen;
		display: block;
		@include font-size(0.875);
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}

/* ON Ajoute class contraste à body */
.contraste #primary-menu li.item_acces a{
	background-color:#000;
	color:#fff;	
}

.contraste{
	.HomePage-BG, .SingleProjet--bg, .Institution{
		background-color:#fff;
	}

	.HomePage-Edito{
		margin-top: 0px;
	}

	.AllProjets-Header-Container, .Institution--bg_jaune, .Institution--bg_bleu{
		background-color:#666;
	}
	.Institution-Insti .TitleSection, .Institution-Partner .TitleSection{
		color:#fff;
	}
	
	@media screen and (max-width: 1023px){
		.Institution-Insti, .Institution-Partner, .HomePage-Projets, #site-navigation .container_menu{
			background-color:#666;
		}
		.HomePage-Projets > .TitleSection{
			color:#fff;
		}
		.HomePage-Edito{
			border-color:#666;
		}
	}

	@media screen and (max-width: 900px){
		.SingleProjet-Header{
			background-color:#666;
			color:#fff;

			&-Title{
				color:#fff;
			}
		}
	}

	@media screen and (max-width: 768px) and (min-width: 426px){
		.SingleProjet-Right:before{
			background-color:#666;
		}
	}

	.SingleCreation:before, .AproposProjet:after, .searchformtop--inerpage{
		border-color:#666;
	}

	.ProjetInfo{
		background-color:#666;
		color:#fff;
	}

	.SearchformTop{
		background-color:#666;
	}


	.TitleSection>span, .searchformtop--inerpage .search-field, .searchformtop--inerpage i.icon-recherche{
		color:#666;
	}

	.Search-Item{
		border-color:#666;
		&:after{
			background-color:#666;
		}
	}

	.Pagination a.current, .Pagination span.current{
		color:#666;
		font-weight:bold;
	}
}

.special_contraste{
	background:#000;
	color:#fff;
	text-align:center;
	padding:10px;
	margin-bottom: 10px;

	p{
		margin:0px;
	}

	@media screen and (max-width: 1023px){
		display:none;
	}
}

