.site-width{
		max-width:1056px;
		margin:0px auto;

	
	&--contact{
		max-width:760px;
	}

	@media screen and (max-width: 1100px) {
		margin-left:40px;
		margin-right: 40px;
	}

	@media screen and (max-width: 768px) {
		margin-left:20px;
		margin-right: 20px;
	}

	&--nomobile{
		@media screen and (max-width: 768px) {
			margin-left:0px;
			margin-right: 0px;
		}
	}
}

#main{
	padding-top: 40px;
	@media screen and (max-width: 1023px) {
		padding-top:50px;
	}

}


.TwoCol{
	&-Container{
		display:flex;
		justify-content:space-between;
		flex-wrap: wrap;
	}
	&-One{
		width:percentage(490px / 1056px);
	}

	&-Two{
		width:percentage(490px / 1056px);
	}

	@media screen and (max-width: 767px) {
		&-One{
			width:100%;

			.InfosTxt &{
				margin-bottom: 30px;
			}
		}

		&-Two{
			width:100%;
		}
	}
}
.THreeCol{
	&-Container{
		display:flex;
		justify-content:space-between;
		flex-wrap: wrap;

		> section{
			width:percentage(325px / 1056px);
			&.THreeCol-Three{
				width:percentage(342px / 1056px);
			}
		}

		@media screen and (max-width: 767px) {
		
			> section{
				width:100%;
				&.THreeCol-Three{
				width:100%;
				}
			}
	}

	}
}

.InfosPratique{
	z-index: 100;
}
.arrow_box{
	position:relative;

	&:after{
		top: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(64, 213, 109, 0);
		border-top-color: #40d56d;
		border-width: 40px;
		margin-left: -40px;
	}

	&.arrow_color__bleu{
		&:after{
			border-color: rgba($color__talents_bleu, 0);
			border-top-color: $color__talents_bleu;
		}
	}

	&.arrow_color__bleuf{
		&:after{
			border-color: rgba($color__talents_bleuf, 0);
			border-top-color: $color__talents_bleuf;
		}
	}

	&.arrow_color__vert{
		&:after{
			border-color: rgba($color__talents_vert, 0);
			border-top-color: $color__talents_vert;
		}
	}

	&.arrow_color__violet{
		&:after{
			border-color: rgba($color__talents_violet, 0);
			border-top-color: $color__talents_violet;
		}
	}
}
.arrow_box:after {
	
}
