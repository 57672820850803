.Gallery{
    
    .slick-slide {
        margin: 0 20px;
      }
      /* the parent */
      .slick-list {
        margin: 0 -20px;
      }

    .slick-track {
      display: flex;
    }
    .slick-track .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center;
    }

    .slick-slide img{
        max-width: 100%;
        height:auto;
    }

	.slick-dots{
		position:absolute;
		width:100%;
		bottom:-25px;
		z-index:500;
		display: flex !important;
		justify-content:center;
		margin:0px;
		padding:0px;



		> li{
			width:10px;
			height:10px;
			margin:4px 10px;
			list-style-type: none;
			position:relative;

			> button{
				display: block;
				width:10px;
				height:10px;
				font-size: 0;
    			line-height: 0;
    			cursor: pointer;
    			color:transparent;
    			border:0;
    			outline: none;
    			background:none;
				border-radius:50%;
				border:1px solid $color__talents_bleuf;

				transition: 0.35s;
				padding:0px;

				&:before{
					display:none;
				}
			}

			&.slick-active{
				> button{
					background:$color__talents_bleuf;
					// &:before{
					// 	background:$color__talents_bleuf;
					// }
				}
			}
		}

		@media screen and (max-width: 767px) {

				// > li{
				// 	width:40px;
				// }
		}

	}

}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 45px;
    height: 65px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
   // font-family: 'slick';
    font-size: 50px;
    line-height: 1;
    opacity: .75;
    color: $color__talents_bleuf;
    transition:0.3s;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -0px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '\e906';
}
[dir='rtl'] .slick-prev:before
{
    content: '\e911';
}

.slick-next
{
    right: -0px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '\e911';
}
[dir='rtl'] .slick-next:before
{
    content: '\e906';
}