
/* COULEUR GDA ARCHITECTES  */

$color__talents_noir : #000000;
$color__talents_bleuf : #161454;
$color__talents_bleu : #26228c;
$color__talents_bleuc:#0950a1;
$color__talents_violet:#8069b0;
$color__talents_rose : #c41a8a;
$color__talents_jaune : #fffe00;
$color__talents_vert : #00947e;


/* COULEUR */
$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #1A1A1A;
$color__link: $color__talents_noir;
$color__link-visited: $color__talents_noir;
$color__link-hover: $color__talents_noir;
$color__text-main: #0C0C0C;

$color__border-button: #3B3482;
$color__border-button-hover: #DF6A9B;
$color__border-button-focus: #DF6A9B;
$color__border-input: #3B3482;
$color__border-abbr: #3B3482;
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 28, 2019 */


@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../fonts/AvenirNextCyr-Light.woff2') format('woff2'),
        url('../../fonts/AvenirNextCyr-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../fonts/AvenirNextCyr-Bold.woff2') format('woff2'),
        url('../../fonts/AvenirNextCyr-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../fonts/AvenirNextCyr-Regular.woff2') format('woff2'),
        url('../../fonts/AvenirNextCyr-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../fonts/AvenirNextCyr-Medium.woff2') format('woff2'),
        url('../../fonts/AvenirNextCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../fonts/AvenirNextCyr-Demi.woff2') format('woff2'),
        url('../../fonts/AvenirNextCyr-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}



@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('../../fonts/AvenirNextCyr-Heavy.woff2') format('woff2'),
        url('../../fonts/AvenirNextCyr-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}





$font__main: "Avenir Next Cyr", helvetica,arial, sans-serif;
$font__title: "Avenir Next Cyr", helvetica,arial, sans-serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;


/* STRUCTURE */

$size__site-main: 100%;
$size__site-sidebar: 25%;


/*  OK UTILE  */
$max_size_MEDIUM_desktop:1450px;
$min_size_MEDIUM_desktop:1210px;
$max_size_tablette:768px;

/* VARIABLE : g = grand */



$max_size_M_desktop:1320px;
$max_size_S_desktop:1200px;
$max_size_g_tablette:1024px;
$max_size_XL_mobile:1023px;

$max_size_L_mobile_plus:700px;


$max_size_tablette_moins:767px;

$max_size_L_mobile:600px;

$max_size_M_mobile:450px;
$max_size_S_mobile:400px;

$min_size_geant_desktop:1401px;

$min_size_g_tablette:1024px;


/* COLUMN */

$columns: (
	1: 100%,
	2: 50%,
	3: 33.33%,
	4: 25%,
	5: 20%,
	6: 16.66%,
	7: 14.28%,
	8: 12.5%,
	9: 11.11%
);

$columns__margin: 3%;
