/***********/
	/* CONCEPT */
/**********/
.Concept{
	padding:50px 0px 85px 0px;
	> .site-width{
		max-width:776px;

		@media screen and (max-width: $max_size_MEDIUM_desktop) {
			.VeryBigTitle{
				margin-bottom:30px;
			}
		}
	}

	@media screen and (max-width: 767px) {
		padding:50px 0px 20px 0px;
	}
}

/***********/
	/* VISITE */
/**********/

.Visite{
	&-Intro{
		padding:60px 0px;
		p{
			line-height:1.3em;
			margin:0px;
			margin-top:25px;
		}
	}

	&-Container{
		display:flex;
		flex-wrap: wrap;
		padding:70px 0px;
		padding-bottom:39px;

		@media screen and (max-width: 580px) {
			justify-content:space-between;
		}
	}

	&-Item{
		width:percentage(331px / 1056px);
		margin-right:percentage(31.5px / 1056px);

		&:nth-child(3n){
			margin-right: 0px;
		}

		text-align: center;
		cursor:default;
		margin-bottom:31px;
		position:relative;
		padding-bottom:120px;
		overflow:hidden;
		background:$color__talents_rose;

		&-Img{
			background:#fff;
			img{
				vertical-align: top;
			}
		}
		&-Desc{
			padding:10px;
			padding-top:8px;
			background:$color__talents_rose;
			color:#fff;

			@include font-size(1);
			height:120px; 
			position:absolute;
			bottom: 0px;
			transition:height 0.4s cubic-bezier(.455, .03, .515, .955) 0s;
			p{
				line-height:1.35em;
				margin:0px;
			}

			@media screen and (max-width: 767px) {
				@include font-size(0.9375);
			}
		}

		&:hover{
			.Visite-Item-Desc{
				height:80%;
			}
		}


		@media screen and (max-width: 767px) {
			padding-bottom:0px;
			background:$color__talents_rose;

			&-Desc{
					position:inherit;
					bottom:inherit;
					height:inherit;
					padding-bottom:10px;
			}
		
		}
		@media screen and (max-width: 580px) {
			width:48%;
			margin:0px;
			margin-bottom:30px;
		}

		@media screen and (max-width: 360px) {
			width:48%;
			&-Desc{
				padding:5px;
				@include font-size(0.875);		
			}
		}
	}
}


/***********/
	/* Programme */
/**********/

.Programme{
	&-Intro{
		padding:75px 0px 120px 0px;

		@media screen and (max-width: 768px) {
			padding:75px 0px 60px 0px;

		}
	}

	&-Container{
		padding:88px 0px 120px 0px;

		@media screen and (max-width: 767px) {
			padding:60px 0px 20px 0px;
		}
	}

	&-Item{
		margin-bottom: 30px;
		&-Title{
			@include font-size(1.9375);
			margin:0px;
			font-weight:600;
			color:$color__talents_bleu;
			line-height:1.3em;
		}

		&-Desc{
			@include font-size(1.125);
			margin:0px;
			color:$color__talents_bleuf;
			line-height:1.3em;
		}

		&-Date{
			color:$color__talents_rose;
			@include font-size(1.4375);
			margin:0px;
			font-weight:600;
			line-height:1.1em;
		}

		@media screen and (max-width: $max_size_MEDIUM_desktop) {
			&-Title{
				@include font-size(1.375);
			}

			&-Desc{
				@include font-size(1);
			}

			&-Date{
		
				@include font-size(1.2);
				}
			}

			@media screen and (max-width: $max_size_MEDIUM_desktop) {
			&-Title{
				@include font-size(1.25);
			}

			&-Desc{
				@include font-size(1);
			}

		&-Date{
	
			@include font-size(1);
			}
		}
	}
}

/***********/
	/* InfosPratique */
/**********/

.InfosPratique{
	&-Intro{
		padding:60px 0px;
		h2{
			line-height:1em;
		}
		p{
			line-height:1.3em;
			margin:0px;
			margin-top:25px;
		}
	}
}
/* DESSOUS InfosPratique  - TXT */
.InfosTxt-Container{
	padding:40px 0px;
	p{
		margin:0px;
		&:nth-child(3){
			margin-top: 30px;
		}
	}
}
