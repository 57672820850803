p {
	margin-bottom: 1.5em;
}

dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 2em 1.5em;
	border-left:4px solid #08414e;
	padding-left:1em;

	p{
		margin-bottom: 10px;
	}

	cite{
		font-style: italic;
		color:#6c7781;
	}
}



address {
	margin: 0 0 1.5em;
}

pre {
	background: $color__background-pre;
	font-family: $font__pre;
	@include font-size(0.9375);
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code, kbd, tt, var {
	font-family: $font__code;
	@include font-size(0.9375);
}

abbr, acronym {
	border-bottom: 1px dotted $color__border-abbr;
	cursor: help;
}

mark, ins {
	background: $color__background-ins;
	text-decoration: none;
}

big {
	font-size: 125%;
}


body,
button,
input,
select,
optgroup,
textarea {
	color: $color__text-main;
	font-family: $font__main;
	@include font-size(1);
	line-height: $font__line-height-body;
}


h1, h2, h3, h4, h5, h6 {
	font-weight:normal;
	line-height:1.3em;
}


.ToDesapear{
	font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}


.txtalignright{
	text-align:right;
}

.wysiwyg{

	@include font-size(1);

	text-align:justify;

	// &--littlemorebig{
	// 	@include font-size(1.0625);
	// }

	&--little{
		@include font-size(0.9375);
	}
	
	

	p,li{
		
		line-height:1.65em;
	}

	ul{
		margin:0px;
		padding:0px;
	}
	li{
		list-style: none;
		padding-left:20px;
		position:relative;
		&:before{
			content:'';
			display:block;
			width:4px;
			height:4px;
			background:#08414e;
			border-radius:50%;
			position:absolute;
			left:0px;
			top:10px;
		}
		line-height:1.8em;
		margin:8px 0px;

		a{
			&:hover{
				text-decoration:underline;
			}
		}
	}

	strong{
		font-weight:500;
	}

	ul{
		margin-left:0px;
		padding-left:1em;
	}

	em{
		color:$color__talents_noir;
		font-style: normal;
	}

	> :first-child{
		margin-top:0px;
	}
	> :last-child{
		margin-bottom:0px;
	}

	@media screen and (max-width: 1023px) {
		@include font-size(1);

		&--little{
			@include font-size(0.875);
		}

	}
	
	
	
	h2{
		display:inline-block;
		border-bottom:1px solid #000;
		font-weight:300;
		font-size:1.3em;
	}

	h3{
		font-size:1.1em;
		text-transform:uppercase;
		font-weight:300;
	}

	@media screen and (max-width: 768px) {
		@include font-size(0.9375);
		
		h2{
			font-size:1.3em;
			padding-bottom:5px;
		}

		h3{
			font-size:1em;
		}
	}


	&--white{
		li{
			&:before{
				background:#fff;
			}
		}

		em{
			color:#fff;
			font-style:italic;
		}

		h2{
			border-bottom-color:#fff;
		}
	}
}


/*  POUR NOTAIRE BUGEAUD */

.VeryBigTitle{
	@include font-size(4.375);
	font-weight:900;
	line-height:0.85em;
	margin:0px;

	@media screen and (max-width: $max_size_MEDIUM_desktop) {
		@include font-size(3.125);
	}

	@media screen and (max-width: $min_size_MEDIUM_desktop) {
		@include font-size(2.5);
	}

	@media screen and (max-width: $max_size_tablette) {
		@include font-size(1.875);
		line-height:1em;
	}
}


/* taille typo */
.typo_26{
	@include font-size(1.625);
}
.typo_25{
	@include font-size(1.5625);
}
.typo_28{
	@include font-size(1.75);
}
.typo_40{
	@include font-size(2.5);
}

@media screen and (max-width: $max_size_MEDIUM_desktop) {
	.typo_26{
		@include font-size(1.25);
	}
	.typo_25{
		@include font-size(1.3125);
	}
	.typo_28{
		@include font-size(1.5);
	}
	.typo_40{
		@include font-size(2.25);
	}
}



@media screen and (max-width: $min_size_MEDIUM_desktop) {
	.typo_26{
		@include font-size(1.25);
	}
	.typo_25{
		@include font-size(1.3125);
	}
	.typo_28{
		@include font-size(1.5);
	}
	.typo_40{
		@include font-size(2.25);
	}
}

@media screen and (max-width: $max_size_tablette) {
	.typo_26{
		@include font-size(1);
	}
	.typo_25{
		@include font-size(1);
	}
	.typo_28{
		@include font-size(1.25);
	}
	.typo_40{
		@include font-size(1.875);
	}
}


/* Section background */

.bg{
	&--bleuf{
		background:$color__talents_bleuf;
	}

	&--bleu{
		background:$color__talents_bleu;
	}

	&--bleuc{
		background:$color__talents_bleuc;
	}

	&--jaune{
		background:$color__talents_jaune;
	}

	&--vert{
		background:$color__talents_vert;
	}

	&--violet{
		background:$color__talents_violet;
	}
}

/* Les couleurs utilisé pour le site */
.color{
	&__blanc{
		color:#fff;
	}
	&__rose{
		color:$color__talents_rose;
		a{
			color:$color__talents_rose;
			text-decoration:underline;
			&:hover{
				color:$color__talents_bleu;
			}
		}
	}

	&__bleu{
		color:$color__talents_bleu;
	}

	&__jaune{
		color:$color__talents_jaune;
	}
}

.cadre_b_rose{
	background:$color__talents_rose;
	color:#fff;
	padding:5px;
	padding-top:7px;
}

.bold{
	font-weight:bold;
}

.semi-bold{
	font-weight:600;
}
.center{
	text-align:center;
}
.btn, .Billet-Txt .btn{
	display:inline-block;
	border:1px solid #fff;
	background:$color__talents_rose;
	color:#fff;
	border-radius:20px;
	@include font-size(1.125);
	font-weight:900;
	position: relative;
	padding:4px 15px;
	text-transform:uppercase;
	transition:0.35s;

	&:after{
		content:'';
		display:block;
		position:absolute;
		top:-3px;
		left:-3px;
		width:100%;
		width:calc(100% + 6px);
		height:100%;
		height:calc(100% + 6px);
		background:$color__talents_bleuf;
		z-index: -1;
		border-radius:20px;

	}

	&:hover{
		background:$color__talents_bleuf;
		text-decoration:none;
	}
}



















