.WidthIntro{
	max-width: 470px;
	margin:0px auto;
	
	padding:65px 0px;

	&--inner{

	}

	p, h2{
		margin:10px 0px;
	}

	h2{
		line-height:1.5em;
	}

	@media screen and (max-width: $max_size_MEDIUM_desktop) {
		text-align: center;
	}
	@media screen and (max-width: $min_size_MEDIUM_desktop) {
		max-width:350px;
	}

	@media screen and (max-width: 768px) {
		max-width:100%;
		padding-left:20px;
		padding-right:20px;
	}


}

.SectionIntroBis{
	text-align: center;
	padding:54px 0px;
	z-index: 150;

	p, h2{
		margin:6px 0px;
	}
}

.intro-bg{
	background:url('../imgs/bg-top-site.jpg') center center;
	min-height: 395px;

	@media screen and (max-width: 767px) {
		display:none;
	}
}