.Mastfoot{
	background:#000;
	padding:2px 0px;
	margin-top:40px;
}

#footer-menu{
	margin:0px;
	padding:0px;
	display: flex;
	flex-wrap:wrap;
	justify-content: center;
	padding:8px 0px 8px 0px;
	li{
		list-style: none;
		color:#fff;
		text-transform: uppercase;
		font-weight: 700;
		@include font-size(0.875);
		&:after{
			content: 'I';
			color:$color__talents_rose;
			font-weight: bold;
			padding-left:12px;

			@media screen and (max-width: 767px) {
				display:none;
			}
			
		}
		a{
			padding:4px 12px;
			color: #fff;
			
			transition: 0.35s;
			&:hover{
				color:$color__talents_jaune;
			}
		}

		&:last-child{
			&:after{
				display:none;
			}
		}


	}
}