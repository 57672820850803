.relative{
	position:relative;
}

.dessin-triangle{
	&--hautdroite{
		width:279px;
		position:absolute;
		right:-350px;
		top:-100px;

		transform:scale(1) rotate(0deg) translate(0px, -100px);
		transition:1s cubic-bezier(.455, .03, .515, .955) 0s;

		&.to_anim{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}
	}

	&--verttop{
		width:303px;
		position:absolute;
		left:-220px;
		top:80px;
		transform:scale(0.8) rotate(0deg) translate(0px, 200px);
		transition:1s cubic-bezier(.455, .03, .515, .955) 0.3s;

		&.to_anim{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}

	}

	&--hautdroite2{
		width:347px;
		position:absolute;
		right:-250px;
		top:-20px;
		transform:scale(0) rotate(0deg) translate(0px, -100px);
		transition:1s cubic-bezier(.455, .03, .515, .955) 0.3s;

		&.to_anim{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}
	}

	&--etoile{
		display:block;
		width:120px;
		position:absolute;
		left:-100px;
		top:-80px;
		transform:scale(0) rotate(0deg) translate(0px, -300px);
		transition:0.8s cubic-bezier(.455, .03, .515, .955) 0.3s;

		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}

		@media screen and (max-width: 768px) {
			width:80px;
			left:-65px;
			top:-55px;
			
		}
	}

	&--bleu3{

		width:318px;
		position:absolute;
		left:0px;
		bottom:-110px;
		transform:scale(1) rotate(5deg) translate(0px, -100px);
		transition:2.5s cubic-bezier(.455, .03, .515, .955) 0.3s;

		&.to_anim{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}

	}

	&--roseright{
		width:313px;
		position:absolute;
		right:5%;
		bottom:-110px;
		transform:scale(1) rotate(5deg) translate(0px, 100px);
		transition:2.5s cubic-bezier(.455, .03, .515, .955) 0.3s;

		&.to_anim{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}
	}

	&--violetdroite{
		width:279px;
		position:absolute;
		right:-340px;
		top:-100px;
		transform:scale(1) rotate(-5deg) translate(0px, 200px);
		transition:2.5s cubic-bezier(.455, .03, .515, .955) 0.3s;

		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}
	}
	&--vert_l{
		width:303px;
		position:absolute;
		left:-440px;
		top:45%;
		transform:scale(1) rotate(5deg) translate(0px, -400px);
		transition:4.5s cubic-bezier(.455, .03, .515, .955) 1.3s;

		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}
	}
	
	&--vert_rightvisite{
		width:279px;
		position:absolute;
		right:-340px;
		top:40%;
		transform:scale(1) rotate(-5deg) translate(0px, 200px);
		transition:0.5s;

		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
			transition:2.8s cubic-bezier(.455, .03, .515, .955) 1.3s;
		}
	}
	
	// Infos pratiques 

	&--jaune_iP_l{
		width:310px;
		position:absolute;
		left:20px;
		top:60%;
		transform:scale(1) rotate(-5deg) translate(0px, -200px);
		transition:0.5s;

		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
			transition:1.8s cubic-bezier(.455, .03, .515, .955) 0.3s;
		}
	}	

	&--rose-IL_R{
		width:347px;
		position:absolute;
		right:30px;
		
		transform:scale(1) rotate(-5deg) translate(0px, 200px);
		transition:2.5s cubic-bezier(.455, .03, .515, .955) 0.3s;

		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}
		
		@media screen and (max-width: 1450px) {
			width:242px;
			top:21%;
		}

	}
	
	&--vetContact{
		width:303px;
		position:absolute;
		left:10%;
		top:-140px;
		transform:scale(1) rotate(-5deg) translate(0px, -200px);
		transition:0.5s;
		z-index: 200;
		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
			transition:4.8s cubic-bezier(.455, .03, .515, .955) 0.3s;
		}

		@media screen and (max-width: 1400px) {
			left:0px;
		}
	}	

	&--jauneContact{
		width:279px;
		position:absolute;
		right:10%;
		top:-190px;
		z-index:200;
		transform:scale(1) rotate(-5deg) translate(0px, 200px);
		transition:2.5s cubic-bezier(.455, .03, .515, .955) 0.3s;

		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}

		@media screen and (max-width: 1400px) {
			right:0px;
		}
	}	

	&--bleuFormL{
		width:318px;
		position:absolute;
		left:10px;
		top:45%;
		transform:scale(1) rotate(-5deg) translate(0px, -200px);
		transition:0.5s;
		z-index: 200;
		.to_anim &{
			transform:scale(1) rotate(0deg) translate(0px, 0px);
			transition:1.8s cubic-bezier(.455, .03, .515, .955) 0.0s;
		}
	}	

	&--vertFormR{
		width:279px;
		position:absolute;
		right:30px;
		top:63%;
		z-index:200;
		transform:scale(1) rotate(-5deg) translate(0px, 200px);
		transition:0.5s;
		.to_anim &{
			transition:2.5s cubic-bezier(.455, .03, .515, .955) 0.8s;
			transform:scale(1) rotate(0deg) translate(0px, 0px);
		}
	}	
	
	
}

// #la-visite{
// 	overflow: hidden;
// }

.PageIn{
	overflow: hidden;
}

@media screen and (max-width: 1200px) {
	#dessin_3, #dessin_triangle_rose, #dessin_triangle_rose_infopL{
		display:none;
	}
}


@media screen and (max-width: 1024px) {
	 #dessin_triangle_vert_contact, #dessin_triangle_jaune_contact, #dessin_triangle_jaune_infopL{
		display:none;
	}
}

#dessin_triangle_bleu_form, #dessin_triangle_vert_form{
	@media screen and (max-width: 1300px) {
		display:none;
	}
}

@media screen and (max-width: 768px) {
	 #dessin_1, #dessin_vert_top{
		display:none;
	}
}
