input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: $color__talents_noir;
	border: 1px solid #fff;
	padding: 8px 10px;
	border-radius:0px;
	background: none;
	@include font-size(0.9375);
	color:$color__talents_noir;
	color: rgba(#000,0.8);
	width:100%;
	transition:0.35s;
	&:focus {
		background:#fff;
		color: $color__talents_noir;
		outline: 0px;
	}
	select{
		background: $color__talents_noir;
	}
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(#fff,0.8);
    opacity: 1; /* Firefox */
  
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(#fff,0.8);
  
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(#fff,0.8);
  
}



/* DEUX COLONNES */

.FormInscription-Container{
	display: flex;
	flex-wrap:wrap;
	justify-content: space-between;
	> div{
		width:48%;
	}

	 @media screen and (max-width:424px) {
	 	> div{
	 		width:100%;
	 	}
	 }
}



.FormInscription{
	p{
		margin:14px 0px;
	}
	label{
		background:$color__talents_rose;
		padding:10px;
		color:#fff;
		text-transform:uppercase;
		font-weight:700;
		@include font-size(1.25);

		white-space: nowrap;

		@media screen and (max-width: 767px) {
			@include font-size(1);
		}
	}

	.for_m_label{
		border:4px solid $color__talents_bleu;
		border-radius:25px;
		display:flex;
		overflow:hidden;
		label{
			//border-radius:20px 0px 0px 20px;
		}
		input{
			border:0px;
			text-align: center;
			flex: 1 1 60%;
			color:$color__talents_violet;
			@include font-size(1.125);
		}
	}
	.only_for_textarea{

		border-radius:25px;
		border:4px solid $color__talents_bleu;
		overflow:hidden;

		label{
			width:100%;
			display:block;
		}

		textarea{
			resize: none;
			width:100%;
			height:140px;
		}
	}

	input[type="submit"]{
		display:inline-block;
		border:4px solid $color__talents_bleu;
		background:$color__talents_rose;
		color:#fff;
		border-radius:25px;
		@include font-size(1.25);
		font-weight:700;
		position: relative;
		padding:8px 25px;
		text-transform:uppercase;
		transition:0.35s;
		cursor:pointer;
		outline:0px;
		&:hover{
			background:$color__talents_bleuf;
			text-decoration:none;
		}

			@media screen and (max-width: 767px) {
				@include font-size(1);
			}
	}

	&-Send{
		text-align: center;
		margin-top:10px;
		position:relative;
		.spinner{
			position:absolute;
			right:0px;
			top:11px;
			display:none;

			.form__to_load &{
				display:block;
				-webkit-animation: loader4 1s ease-in-out infinite;
				animation: loader4 1s ease-in-out infinite;
			}
		}

	}

	.Txt_bottom_form{
		color:$color__talents_violet;
		margin-top:14px;
		@include font-size(1);
		a{
			background:$color__talents_jaune;
			color:$color__talents_rose;

			&:hover{
				background:$color__talents_rose;
			color:#fff;
			}
		}
	}

}







/*****************/
/* LES MESSAGES D'ERREURS + OK  */
/*****************/

.InfoMessage,.wpcf7 .wpcf7-response-output{
	margin-top:20px;	
	padding:10px;
	border:1px solid $color__talents_bleu;
	text-align: center;
	border-radius:20px;
	p{
		margin:5px 0px;
	}
	&.error, &.wpcf7-validation-errors{
		border-color:red;
		color:red;
		background:rgba(red, 0.1);
	}
	 &.wpcf7-validation-errors{
	 	padding:15px 15px;
	 }

	&.success, &.wpcf7-mail-sent-ok{
		border-color:#333;
		color:#fff;
		background:$color__talents_bleu;
	}
}







.spinner,div.wpcf7 .ajax-loader{
	width:55px;
	height:55px;
	margin:0px auto;
	display:inline-block;
	padding:0px;
	border-radius:100%;
	border:5px solid;
	border-top-color:rgba($color__talents_rose, 1);
	border-bottom-color:rgba($color__talents_bleuf, 0.3);
	border-left-color:rgba($color__talents_rose, 1);
	border-right-color:rgba($color__talents_bleuf, 0.3);
	background:none;
	
	&--form{
		width:40px;
		height:40px;
		border-width:4px;
	}
}


@keyframes loader4 {
   from {transform: rotate(0deg);}
   to {transform: rotate(360deg);}
}
@-webkit-keyframes loader4 {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(360deg);}
}












/* RECAPTCHA */
.grecaptcha-badge{
	visibility: collapse !important;  
}












