.Partenaires{
	&-Intro{
		padding:60px 0px;
	}

	&-Section{
		margin-top: 20px;
		padding:15px 0px;
		border-top:5px solid $color__talents_bleuf;

		@media screen and (max-width: 767px) {
			padding-top:10px;
			padding-bottom:20px;
			border-top:2px solid $color__talents_bleuf;
		}	

		h3{
			text-align: center;
			color:$color__talents_bleuf;
			@include font-size(1.75);
			font-weight: 700;
			text-transform: uppercase;

					@media screen and (max-width: 767px) {
						margin-top:30px;
						@include font-size(1.25);
					}
		}

		&--first{
			margin-top:0px;
			border-top:0px;
		}
	}
}

/***************/
/* PART 1 / FINANCEURS */
/***************/

.Part-Financeur{
	display: flex;
	flex-wrap:wrap;
	justify-content: space-between;
	align-items: center;
}

/***************/
/* PART 2 / PARTENAIRES STAND SUR EVENT */
/***************/


.Part-Partenaire-Stand{
	display: flex;
	flex-wrap:wrap;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: 768px) {
			padding-left:50px;
			padding-right:50px;

			&-Item{
			padding-top: 5px;
			padding-bottom:5px;
			padding-left:20px;
			padding-right:20px;
		}

	}
	
}

/***************/
/* PART 3 / PARTENAIRES AUTRES */
/***************/


.Part-Partenaire-Autre{
	display: flex;
	flex-wrap:wrap;
	justify-content: center;
	align-items: center;
	

	@media screen and (min-width: 768px) {
		padding-left:50px;
		padding-right:50px;

		&-Item{
			padding-top: 5px;
			padding-bottom:5px;
			padding-left:12px;
			padding-right:12px;
	}
	}

	
}

#partenaires{
		@media screen and (max-width: 767px) {
			padding-bottom:10px;
		}
}

