.gallery {
	margin-bottom: 1.2em;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;
	margin:0.8em 0px;
	// Loops to enumerate the classes for gallery columns.
	@for $i from 2 through 9 {
		.gallery-columns-#{$i} & {
			max-width: map-get( $columns, $i );
		}
	}

	@media screen and (max-width: 900px) {
		
		.wysiwyg &{
			max-width:32%;
			margin-top:0px;
			margin-bottom: 5px;
			margin-right:2%;
			&:nth-child(3n){
				margin-right: 0px;
			}
		}
	}

	img{
		vertical-align:top;
		transition: 0.5s;
	}
	
	padding:0px;

	a{
		position:relative;
		display:block;
			&:before{
			content: "\e90b";
			position:absolute;
			left:50%;
			top:50%;
			transform: translate(-50%, -50%) scale(0);
			background:rgba($color__talents_noir, 0.8);
			padding:20px;
			color:#fff;
			transition:0.5s;
		}

		&:hover:before{
			transform: translate(-50%, -50%) scale(1);
		}

	}
	

	
}

.gallery-caption {
	display: block;
}
