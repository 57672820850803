.Masthead{
	position: fixed;
	top:0px;
	left:0px;
	width:100%;
	z-index:999;
	background: #000;

	.logo-branding{
		position:fixed;
		top:0px;
		left:0px;
		padding-left:45px;
		transition:0.34s;
		max-width:350px;

		@media screen and (max-width: $max_size_MEDIUM_desktop) {
			max-width:250px;
			padding-left:35px;
		}
		@media screen and (max-width: 1200px) {
			max-width:220px;
			padding-left:25px;
		}
		//background:#fff;

		.bg-logo{
			position:absolute;
			width:440px;
			width:129%;
			top:0px;
			left:0px;
			z-index: -1;
			transition:0.34s;
		}

		@media screen and (max-width: 1023px) {
			max-width: 120px;
			padding-right:10px;
			padding-left:10px;
		}
	}

	&.Masthead--sticky{
		.logo-branding{
			max-width: 150px;
			padding-left:25px;

			@media screen and (max-width: 1023px) {
				max-width: 120px;
				padding-right:10px;
				padding-left:10px;
			}
		}
	}
}

#primary-menu{
	margin:0px;
	padding:0px;
	display: flex;
	justify-content: center;
	padding:8px 0px 8px 0px;
	li{
		list-style: none;
		&:after{
			content: 'I';
			color:$color__talents_rose;
			font-weight: bold;
			
		}
		a{
			padding:4px 12px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
			@include font-size(0.875);
			transition: 0.35s;
			outline: 0px;
			&:hover{
				color:$color__talents_jaune;
			}
		}

		&:last-child{
			&:after{
				display:none;
			}
		}

		&.active_menu a{
			color:$color__talents_jaune;
		}
	}

	@media screen and (max-width: 1200px) {
		justify-content:flex-end;
	}
}




.menu-toggle{
	display:none;
}




/* DESIGN DU BOUTON POUR MOBILE */
.menu-toggle {
	padding: 0px;
	width: 40px;
	height: 40px;
	border: 0px;
	outline: 0px;
	background:none;

	&:active, &:focus,&:hover{
		background:none;
	}
}
.menu-toggle .txt {
	font-size: 0;
	visibility: hidden;
}
.menu-toggle .menu {
	position: absolute;
	left: 5px;
	top: 16px;
	z-index: 100;
	width: 30px;
	height: 15px;
	transition: .2s ease-in-out;
	cursor: pointer;
}

.menu-toggle .hamburger:after, .menu-toggle .hamburger:before {
	transition: .2s ease-in-out;
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	background: #fff;
}

.menu-toggle .hamburger {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 24px;
	height: 3px;
	background: #fff;
	display: block;
	transform-origin: center;
	transition: .2s ease-in-out;

	// .fixed_scrol_mobile &,.bg_bleu &{
	// 	background: #272727;
	// 	&:before, &:after{
	// 		background: #272727;
	// 	}
	// }

	.toggled &{
		background: #fff;

		&:before, &:after{
			background: #fff;
		}
	}

}


.menu-toggle .hamburger:before {
	top: -9px;
}
.menu-toggle .hamburger:after {
	bottom: -9px;
}

.toggled .hamburger {
	width:26px;
	background:#fff;
	&:before, &:after{
		width:26px;
		background:#fff;
	}
}

.toggled .hamburger {
	transform: rotate(45deg);
}
.toggled .hamburger:after {
	transform: rotate(90deg);
	bottom: 0;
}
.toggled .hamburger:before {
	transform: rotate(90deg);
	top: 0;
}

/* FIN DESGIN POUR BOUTON MOBILE */	

.menu-toggle{
	display: none;
}




/* Format labtop */
@media screen and (max-width: 1023px) {
		
	#masthead{
		transition:background 0.4s;
		
		min-height:50px;
	}

	/* MENU MOBILE ! */
	.menu-toggle{
		position: fixed;
		top:7px;
		right: 7px;
		display: block;
		z-index:2;
	}

	#site-navigation{
		 .container_menu{
			position: fixed;
		    left: 0;
		    top:0px;
		    width:100%;
		    z-index:-1;
		    background:green;
		    bottom: -92px;
		    overflow: hidden;
		 	z-index: 1;
		 	height:0px;
	    	transition: height 0.8s cubic-bezier(0.165, 0.84, 0.44, 1) 0.1s;
	    	background:$color__talents_bleuf;

	    	ul{
	    		transform: translate3d(0, -100vh, 0);
				-webkit-transition: -webkit-transform 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
				transition: -webkit-transform 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
				transition: transform 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
				transition: transform 0.9s cubic-bezier(0.165, 0.84, 0.44, 1),-webkit-transform 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);

				flex-direction:column;
				text-align:center;
				height:100%;
				padding:50px 0px;
				margin:0px;

				li{
					margin:auto 0px;
					
					&:after{
						display:none;
					}

					a{
						@include font-size(1.25);
						color:#fff;
					}
					
					&.active_menu{
						a{
							color:$color__talents_jaune;
						}
					}
				}
				@media screen and (max-height: 470px) {
					padding:70px 0px;
				}
    		}
    	}

    	&.toggled{

			 .container_menu{
				height:calc(100vh - 0px);
				height: calc((var(--vh, 1vh) * 100) - 0px);
	        	transition: height 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
				
	        	ul{
				 -webkit-transform: translateZ(0);
       			 transform: translateZ(0);
       			 padding:20% 0px 23% 0px;
       			  padding-top:10%;
       			  padding-bottom:30%;

       			 @media screen and (max-height: 470px) {
       			 	  padding:40px 0px 80px 0px;
       			 }

       			 @media screen and (max-height: 320px) {
       			 	padding:30px 0px 50px 0px;

       			 }

       			
       			 //   -webkit-transform: translate3d(0, 360px, 0);
       				// transform: translate3d(0, 360px, 0)
				}

        	}
			
		}

	}


	// #site-navigation.toggled ul {
	// 	display: block;
	// 	padding-bottom: 20px;
	// }
	#header #menu{
		float: none;
	}
	#menu-primary li{
		float: none;
		border-bottom:1px solid #e0e0e0;
		width: auto;
	}

	#menu-primary li:last-child{
		border-bottom: none;
	}
	
	#menu-primary li a{
		padding: 10px 20px;
		line-height: 1em;
		text-transform: none;
	}
	#menu-primary li a:hover{
		background: #f5f5f5;
	}

}